.mainTerm{
    text-align: left;
    padding: 150px;
    padding-top: 0;
}

.mainTerm p {
    line-height: 40px;
}


.mainTerm h2 {
    padding-top: 80px;
    padding-bottom: 15px;
    color: #814F8C;
}

.mainTerm h4 {
    padding-top: 30px;
    padding-bottom: 15px;
}

.mainTerm > p {
    margin-left: 30px;
    text-align: justify;
}

.mainTerm a {
    color: #814F8C;
}

.mainTerm p {

    padding-bottom: 20px;
}

.mainTerm ul{
    padding-left: 30px;
    line-height: 40px;
}

.addressBar p {
    margin-left: 30px;
    padding: 0;
}