.protocol{
    background-color: #F4F7FF;
    margin-top: 8em;
    padding: 6em;
    @media screen and(max-width:1100px){
        padding: 4em;
    }
    @media screen and(max-width:650px){
        padding: 2em;
        padding-top: 4em;
        padding-bottom: 4em;
        margin-top: 4em;
    }
    @media screen and (min-width:1450px){
        padding-right:8em;
        padding-left: 8em;
      }
        h1{
            text-align: center;
            color: #814F8C;
            font-weight: 700;
            font-size: 3em;
            padding-bottom: .5em;
            text-transform: uppercase;
            @media screen and (max-width:1100px){
                font-size: 2em;
            }
        }
        .m-pr{
            display: flex;
            padding-top: 4em;
            @media screen and(max-width:1100px) and (min-width:650px){
                flex-wrap: wrap;
                justify-content: center;
            }
            @media screen and(max-width:650px){
                display: grid;
                justify-items: center;
            }
                .pr{
                width: 25%;
                text-align: center;
                @media screen and(max-width:1100px)and (min-width:650px){
                width: 50%;
                padding-right: 1em;
                }
                @media screen and(max-width:650px){
                    width: 100%;
                }
                h2{
                    color: #814F8C;
                    padding-top: 1em;
                    text-transform: uppercase;
                    font-weight: 400;

                }
                h4{
                    padding-top: .8em;
                    color: #404040;
                    font-weight: 400;

                }
            }
            @media screen and(max-width:1100px){
                .pr:nth-child(3){
                    padding-top: 3em;
                }
                .pr:nth-child(4){
                    padding-top: 3em;
                }
                }
                @media screen and(max-width:650px){
                    .pr:nth-child(2){
                        padding-top: 3em;

                    }
                }
        }
    
}