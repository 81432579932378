.t_hero{
    background-color: #F4F7FF;
    padding-top: 8em;
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 8em;
    @media screen and (min-width:1450px){
        padding-right: 7em;
        padding-left: 7em;
        padding-top: 10em;
      }
      @media screen and (max-width:1200px) and (min-width:550px){
          padding-left: 2em;
          padding-right: 2em;
          padding-top: 4em;
      }
   
      @media screen and (max-width:550px) and (min-width:0px){
        padding-left: 2em;
        padding-right: 2em;
        padding-top: 2em !important;
    }
    .heading-area{
        display: flex;
        align-items: center;
        @media screen and (max-width:1150px){
            display: block;
        }
        .head{
            width: 45%;
            text-align: center;
            @media screen and (min-width:1450px){
                width: 50%;
            }
            @media screen and (max-width:1150px){
                width: auto;
            }
            h1{
                color: #814F8C;
                font-weight: 700;
                padding-left: 1em;
                font-size: 4em;
                @media screen and (min-width:1650px){
                    font-size: 5em;
                }
                text-transform: uppercase;
                text-align: start;
                @media screen and (max-width:1100px){
                    font-size: 2.5em;
                }
                @media screen and (max-width:1150px){
                    padding-left: 0;
                }
            }
        }
        .desc{
            width: 55%;
            @media screen and (max-width:1150px){
                width: auto;
                padding-top: 2em;
            }
            h2{
                font-weight: 400;
                color: #404040;
                text-transform: uppercase;
                @media screen and (max-width:1100px){
                    font-size: 1.2em;
                }
                @media screen and (min-width:1450px){
                    font-size: 1.4vw;
                }
            }
        }
    }
    .team_img{
        img{
            width: 87vw;
        }
        text-align: center;
        margin-top: 6em;
        @media screen and (max-width:1200px) and (min-width:550px){
        margin-top: 4em;
        }
        @media screen and (max-width:550px) and (min-width:0px){
       margin-top: 2em;
       width: 80vw;
        }
    }
}
.management{
    margin-top: 9em !important;
}
.founder,.management{
    text-align: center;
    margin-top: 5em !important;
    padding-left: 8em;
    padding-right: 8em;
    @media screen and (min-width:1450px){
        padding-right: 8em;
        padding-left: 8em;
        margin-top: 0em;
        
      }
      @media screen and (max-width:1110px) and (min-width:550px){
          padding-left: 4em;
          padding-right: 4em;
      }

      @media screen and (max-width:550px) and (min-width:0px){
        padding-left: 2em;
        padding-right: 2em;
        margin-top: 0em !important;
    }
    h1{
        text-transform: uppercase;
        color: #814F8C;
        font-size: 3em;
        @media screen and (max-width:1110px) {
        padding-bottom: .5em;
        padding-top: .5em;
        }
        @media screen and (max-width:550px) and (min-width:0px){
            font-size: 2em;
            margin-top: 8em;
        }
    }
    .imgs{
        display: flex;
        flex-wrap: wrap;
        margin-top: 4em;
        margin-right: 1px;
        
        .im{
            width: 28%;
            height: 500px;
            margin-right: 5%;
            margin-top: 30px;

            @media screen and(max-width:1490px) {
                width: 32%;
                margin-right: 0;

            }

            @media screen and(max-width:1350px) {
                width: 40%;
                margin-right: 0;
                margin-left: 5%;
                height: auto;
                margin-top: 4em;
            }


            @media screen and(max-width:1280px) {
                width: 40%;
                margin-left: 50px;
            }

            @media screen and(max-width:1022px) {
                width: 40%;
                margin-left:50px
            }

            @media screen and(max-width:628px) {
                width: 100%;
                // margin-right: 0px;
                height: auto;
                margin-top: 4em;
                margin-left: 35px;
            }

            @media screen and(max-width:540px) {
                width: 100%;
                // margin-right: 0px;
                height: auto;
                margin-top: 4em;
                margin-left: 30px;
            }

            @media screen and(max-width:414px) {
                width: 100%;
                // margin-right: 10px;
                height: auto;
                margin-top: 2em;
                margin-left: 0px;
            }

            @media screen and(max-width:375px) {
                width: 100%;
                // margin-right: 10px;
                height: auto;
                margin-top: 2em;
                margin-left: 0px;
            }

            .image-area{
                margin-left: 13%;            
                border-radius: 14px;
                overflow: hidden;
                max-width: 360px;
                img {
                    width: 100%;
                    height: 395px;
                }
                @media screen and(max-width:868px) {
                    img {
                        width: 100%;
                        height: 295px;
                    }
                }
                @media screen and(max-width:628px) {
                    width: 100%;
                    // margin-right: 0px;
                    height: auto;
                    margin-top: 4em;
                    margin-left: 40px;
                }
                @media screen and(max-width:540px) {
                    width: 100%;
                    // margin-right: 10px;
                    height: auto;
                    margin-top: 2em;
                    margin-left: 7%;
                }  
                @media screen and(max-width:414px) {
                    width: 100%;
                    // margin-right: 10px;
                    height: auto;
                    margin-top: 2em;
                    margin-left: 0px;
                    img {
                        height: 295px;
                    }
                }    
                @media screen and(max-width:375px) {
                    width: 100%;
                    height: auto;
                    margin-top: 2em;
                    margin-left: 0px;
                    img {
                        height: 295px;
                    }
                }

                @media screen and(max-width:280px) {
                    img {
                        width: 100%;
                        height: 195px;
                    }
                }
            
        }
        h2{
            color: #814F8C;
            margin-top: 1em;
           
        }
        h4{
            color: rgb(11, 15, 59);
            font-weight: 400;
        }
    }
        .c1{
            background-color: #F4F7FF;
        }
        .c2{
            background-color: #F8DAFF;
        }
        .c3{
            background-color: #F6F6F6;
        }
    }
    .im:last-child{
        margin-right: 0;
    }
    .im:first-child{
        @media screen and(max-width:700px) {
        margin-top: 0;
        }
    }
}