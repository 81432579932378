.whyUnbc {
    h1 {
        text-align: center;
        margin-bottom: 2em;
        font-size: 4em;
        color: #6a8d73;

        @media screen and(max-width:850px) {
            font-size: 3em;
            margin-bottom: 1em;
        }
        @media screen and(max-width:550px) {
            font-size: 2em;
            margin-bottom: 0em;
        }
    }
    .content {
        padding-left: 5em;
        padding-right: 5em;
        @media screen and (min-width: 1450px) {
            padding-right: 8em;
            padding-left: 8em;
        }
        @media screen and (max-width: 1110px) and (min-width: 550px) {
            padding-left: 4em;
            padding-right: 4em;
        }

        @media screen and (max-width: 550px) and (min-width: 0px) {
            padding-left: 2em;
            padding-right: 2em;
            margin-top: 4em;
        }
        display: grid;
        grid-template-columns: 40% 60%;
        @media screen and (max-width: 1110px) and (min-width: 0px) {
            display: block;
        }
        .w1 {
            background: rgb(106, 141, 115);
            background: linear-gradient(80deg, rgba(106, 141, 115, 1) 50%, rgba(224, 255, 112, 0.7) 100%);
            border-radius: 20px;
            border: 4px solid #e0ff70;
            .whybg {
                padding-top: 6em;
                padding-left: 2em;
                padding-right: 2em;
                text-align: center;
                height: 100%;
                @media screen and (max-width: 1110px) and (min-width: 0px) {
                    padding: 2em;
                }
                img {
                    width: 80%;
                    @media screen and (max-width: 1110px) and (min-width: 0px) {
                        width: 40%;
                    }
                }
                h2 {
                    color: #e0ff70;
                    font-size: 2.5em;
                    margin-top: 1em;
                    text-transform: uppercase;
                    @media screen and (max-width: 1100px) {
                        font-size: 2em;
                    }
                    @media screen and (max-width: 650px) and (min-width: 0px) {
                        font-size: 1.5em;
                    }
                }
                h4 {
                    color: #fff;
                    font-weight: 400;
                    padding-top: 1em;
                    font-size: 1.5em;
                    @media screen and (max-width: 650px) and (min-width: 0px) {
                        font-size: 1em;
                    }
                }
            }
        }
        .row {
            margin-left: 1em;
            @media screen and(min-width:1450px) {
                margin-left: 2em;
            }
            @media screen and (max-width: 1110px) and (min-width: 0px) {
                margin-left: 0;
                margin-top: 2em;
            }
            .w2r {
                height: 350px;
                background: rgb(106, 141, 115);
                background: linear-gradient(60deg, rgba(106, 141, 115, 1) 60%, rgba(224, 255, 112, 0.7) 100%);
                border-radius: 20px;
                border: 4px solid #e0ff70;
                @media screen and (max-width: 1110px) and (min-width: 0px) {
                    height: auto;
                }
                .bgi {
                    padding: 2em;
                    height: inherit;
                    border-radius: 20px;
                    display: flex;
                    justify-items: center;
                    @media screen and (max-width: 1110px) and (min-width: 0px) {
                        display: block;
                        text-align: center;
                        height: auto;
                    }
                    @media screen and(min-width:1450px) {
                        padding-left: 3em;
                        padding-right: 3em;
                    }
                }
                img {
                    width: 20vw;
                    @media screen and(min-width:1550px) {
                        width: 30vw;
                    }
                    @media screen and (max-width: 1110px) and (min-width: 0px) {
                        width: 36% !important;
                    }
                }
                .text {
                    margin: auto;
                    margin-left: 2em;
                    @media screen and(min-width:1450px) {
                        margin-left: 3em;
                    }
                    @media screen and (max-width: 1100px) {
                        margin-left: 0;
                    }
                    h2 {
                        color: #e0ff70;
                        font-size: 2em;
                        margin-top: 1em;
                        text-transform: uppercase;
                        @media screen and(min-width:1550px) {
                            font-size: 2.5em;
                        }
                        @media screen and (max-width: 1100px) {
                            font-size: 2em;
                        }
                        @media screen and(max-width:850px) {
                            margin-top: 1em;
                        }
                        @media screen and (max-width: 650px) and (min-width: 0px) {
                            font-size: 1.5em;
                            margin-top: 1em;
                        }
                    }
                    h4 {
                        color: #fff;
                        font-weight: 400;
                        padding-top: 1em;
                        font-size: 1.5em;
                        @media screen and(min-width:1550px) {
                            font-size: 2em;
                        }
                        @media screen and (max-width: 650px) and (min-width: 0px) {
                            font-size: 1em;
                        }
                    }
                }
            }
            .w2r:last-child {
                margin-top: 1em;
                img {
                    @media screen and(min-width:1550px) {
                        width: 30vw;
                    }
                }
                @media screen and (max-width: 1110px) and (min-width: 0px) {
                    margin-top: 2em;
                }
                @media screen and(min-width:1450px) {
                    margin-top: 2em;
                }
            }
        }
    }
    margin-top: 8em;
}
