.spin {
    text-align: center;
    margin-top: 8em;
    h1 {
        color: #6a8d73;
        font-weight: 700;
        font-size: 3em;
        padding-bottom: 0.5em;
        text-transform: uppercase;
        text-align: center;
        @media screen and (max-width: 1100px) {
            font-size: 2em;
        }
        @media screen and(max-width:850px) {
            margin-top: 2.4em;
        }
        @media screen and(max-width:550px) {
            margin-top: 1em;
        }
    }
    .spinbtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 2px solid #6a8d73;
        border-radius: 15px;
        padding: 1em 1.2em;
        width: fit-content;
        margin: auto;
        img {
            width: 16px;
            margin: auto;
            padding-top: 3px;
        }
        .icon {
            background-color: #6a8d73;
            width: 28px;
            height: 28px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
        }
        h4 {
            color: #6a8d73;
            padding-left: 1em;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    .spinbtn:hover {
        background-color: #e0ff70;
        border: 2px solid #e0ff70;

        h4 {
            color: #6a8d73;
        }

        .spin-area {
            // width: 28px;
            // height: 28px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            img {
                padding-top: 0px;
            }
        }
    }
    .spining-area {
        margin-top: 4em;
        margin-bottom: 4em;
        @media screen and (max-width: 1100px) {
            margin-top: 2em;
            margin-bottom: 2em;
        }
        img {
            @media screen and (max-width: 1100px) {
                width: 70%;
            }
        }
    }
}
