.mainPolicy h5{
    padding: 10px;
}

table, tr, td, th {
    border:1px solid black;
    padding: 10px;
    border-collapse: collapse;
}


.mainPolicy{
    text-align: left;
    padding: 150px;
    padding-top: 0;
    margin-top: 50px;
}

.mainPolicy p {
    line-height: 40px;
}


.mainPolicy h2 {
    padding-top: 80px;
    padding-bottom: 15px;
    color: #814F8C;
}

.mainPolicy h4 {
    padding-top: 30px;
    padding-bottom: 15px;
    color: #814F8C;
}

.mainPolicy > p {
    margin-left: 30px;
    text-align: justify;
}

.mainPolicy a {
    color: #814F8C;
}

.mainPolicy p {

    padding-bottom: 20px;
}

.mainPolicy ul{
    padding-left: 30px;
    line-height: 40px;
}

.privacyAddresses p{
    margin: 0;
    padding: 0;
    margin-left:30px;
}