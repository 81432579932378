.UNBCpartners {
    margin-top: 8em !important;
    width: 75%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px) {
        width: 90%;
        margin-top: 4em !important;
    }

    h1 {
        color: #6a8d73;
        font-size: 3em;
        text-align: center;
        @media screen and (max-width: 1100px) {
            font-size: 2em;
        }
    }
    .slider {
        height: 150px;
        margin: 4rem auto auto auto;
        overflow: hidden;
        position: relative;
        width: auto;

        .slide-track {
            animation: scroll 40s linear infinite;
            display: flex;
            width: calc(150px * 14);
        }
        .partner-logo {
            // height: 100px;
            // width: 150px;
            margin-right: 4em;

            img {
                width: 100%;
            }
        }
        .gpe {
            img {
                width: 70%;
            }
        }
    }

    //animation

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-150px * 11));
        }
    }
}
