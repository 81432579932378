
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bg-col{
  background-color: #F4F7FF;
}
li{
  cursor: pointer;
}
.header {
  background-color: #F4F7FF;

  padding: 2em 5em;
  z-index: 99;
@media screen and( max-width:99999999999999px) and (min-width:1450px){
  padding-right: 8em;
  padding-left: 8em;
  
}
  @media screen and (max-width: 1200px) {
    padding: 2rem 2rem;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .brand {
    width: 6rem;
    @media screen and (max-width: 950px) {
      width: 4rem;
    }
    img {
      width: 100%;
      margin-left: -0.5rem;
    }
  }
  .menu.active {
    display: block;
    
    width: 100%;
    height:fit-content;
    position: absolute;
    left: 0;
    top: 8rem;
    padding-bottom: 2em;
    .ply{
      @media screen and(max-width:950px){
        display: none;
        
      }
    }
  }

  .menu {
    @media screen and (max-width: 950px) {
      display: none;
    }
    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      @media screen and (max-width: 950px) {
        display: block;
      }

      li {
        padding: 0 1rem;

        @media screen and (max-width: 950px) {
          padding: 1rem 2em;
     
        }
        .more{
          padding-left: .5em;
          padding-bottom: 2px;
          width: 1.1em;
        }
        a {
          // font-size: 18px;
          letter-spacing: 1px;
          text-decoration: none;
          color: #2C2C2C;
        }

        &:last-child {
          padding-right: 0;
        }
      }
      .activehov,.exp:hover{
        background-color: #fff;
        padding: 1em;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        @media screen  and(max-width:950px){
            padding-left: 2em;
            background-color: #F4F7FF;
        }
        .dropdown-content{
          display: block;
        }
      }
      .about:hover{
        background-color: #fff;
        padding: 1em;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: fit-content;
        @media screen  and(max-width:950px){
          padding-left: 2em;
          background-color: #F4F7FF;
      }
    
        .about_dropdown{
          display: block;
          margin-left: 4.8em;
          padding-top: .5em;
          padding-bottom:1em;
          li{
            display: block;
            padding-left: 0em;
            padding-right: 1em;
            padding-top: 1em;
          }
          li:hover{
            text-decoration: underline;
          }
          @media screen  and(max-width:950px){
           margin-left: 0;
          }
        }
      }
      .dropdown-content{
        display: none;
        width: fit-content;
        padding: 2em 1em;
        margin-top: 1em;
         background-color: #fff;
        position: absolute;
        border-radius: 12px;
        border-top-left-radius: 0px;
        @media screen  and(max-width:950px){
          position: relative;
          padding-left: 0em;
          padding-top: 1em;
          background-color: #F4F7FF;
        }
        .btns{
          width: fit-content;
          .bt{
            width: 100%;
            margin-top: 1em;
          }
        }
      }
      .about_dropdown{
        display: none;
        width: fit-content;
        padding: 2em 1em;
        margin-top: 1em;
         background-color: #fff;
        position: absolute;
        border-radius: 12px;
        border-top-left-radius: 0px;
        @media screen  and(max-width:950px){
          position: relative;
          padding-left: 0em;
          padding-top: 1em;
          background-color: #F4F7FF;
        }
      }
      .social_dropdown{
        display: none;
        width: fit-content;
        padding: 2em 1em;
        margin-top: 1em;
         background-color: #fff;
        position: absolute;
        border-radius: 12px;
        border-top-left-radius: 0px;
        @media screen  and(max-width:950px){
          position: relative;
          padding-left: 0em;
          padding-top: 1em;
          background-color: #F4F7FF;
        }
      }
      .social:hover{
        background-color: #fff;
        padding: 1em;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: fit-content;
        @media screen  and(max-width:950px){
          padding-left: 2em;
          background-color: #F4F7FF;
      }
      
      .social_dropdown{
        display: block;
        margin-left: 15.75em;
        padding-top: .5em;
        padding-bottom:1em;
     
        li{
          display: block;
          padding-left: 0em;
          padding-right: 1em;
          padding-top: .5em;
          a{
            display: flex;
            align-items: center;
            img{
              margin-right: 1em;
            }
          }
        }
        li:hover{
          text-decoration: underline;
        }
        @media screen  and(max-width:950px){
         margin-left: 0;
        }
      }
      }
      .m-link {
        li{
          display: inline;
          a{
            color: #2C2C2C;
          }
          @media screen and (max-width: 950px) {
            display: block;
          }
        }
      }
      
    }
  }
  .hamburger {
    display: none;
    @media screen and (max-width: 950px) {
      display: block;
      div {
        width: 35px;
        height: 4px;
        background-color: #814F8C;
        margin: 6px 0;
      }
    }
  }
}
.bg{
  background-color: #F4F7FF;
  .active{
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-color: #F4F7FF;
    .m-link li{
      position: relative;
      z-index: 2;
    }
  }

}