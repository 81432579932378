.road-hero{
    background-image: url('../../assets/roadmapbg.png');
    background-size: cover;
    background-position-y: -8em;
    background-repeat: no-repeat;
   

    @media screen and(max-width:950px) and (min-width:796px){
        background-image: url('../../assets/roadmap-tablet-bg.png');
        height: 93vw;
        background-position-y: 0em;

    }
    @media screen and(max-width:797px) and (min-width:736px){
        background-image: url('../../assets/hero-bg-tablet.png');
        height: 100vw;
        background-position-y: 0em;

    }

    @media screen and(max-width:737px) and (min-width:700px){
        background-image: url('../../assets/hero-bg-tablet.png');
        height: 100vw;
        background-position-y: 0em;

    }


    @media screen and(max-width:350px) {
        background-image: url('../../assets/hero-bg-tablet.png');
        height: 300vw;
        background-position-y: 0em;
    }
    @media screen and(max-width:375px) {
        background-image: url('../../assets/hero-bg-tablet.png');
        height: 160vw;
        background-position-y: 0em;
    }
    @media screen and(max-width:332px) {
        background-image: url('../../assets/hero-bg-tablet.png');
        height: 200vw;
        background-position-y: 0em;
    }
    @media screen and(max-width:280px) {
        background-image: url('../../assets/hero-bg-tablet.png');
        height: 230vw;
        background-position-y: 0em;
    }
    
    .main{
        text-align: center;
        padding-top: 4em;
        .utopia42roadmap{
            width: 100%;
            margin-top: 50px;
        }
        .hero-img1{
            @media screen and(max-width:950px){
                display: none;
                // display: block;
                // position: relative;
                // float: none;
                // left: auto;
                // margin: auto;
                // -webkit-margin-before:0;
            }
            position: absolute;
            float: right;
            left:75%;
            margin-top: -10em;
            -webkit-margin-before: -8em;
        
        }
        .hero-img2{
            position: absolute;
            float: left;
            right:80%;
            margin-top: -2em;
            -webkit-margin-before: -1em;
            @media screen and(width:912px){
                display: none;
            }
            @media screen and(max-width:1120px){
                display: none;
            }
            img{
                width: 65%;   
            }
        
        }
        h1{
            color: #814F8C;
            font-weight: 700;
            font-size: 3em;
            padding-bottom: .5em;
            padding-top: .5em;
            text-transform: uppercase;
            @media screen and (max-width:1100px){
                font-size: 2em;
            }
            @media screen and(max-width:850px) {
                margin-top: .5em;
            }
            @media screen and(max-width:550px) {
                margin-top: 1em;
            }
        }
        .desc{
            display: flex;
            justify-content: center;
            padding-left: 2em;
            padding-right: 2em;
            h2{
                font-weight: 400;
                color: #404040;
                text-transform: uppercase;
                width: 55%;
                @media screen and(max-width:850px){
                    width: auto;
                }
                @media screen and (max-width:1100px){
                    font-size: 1.2em;
                }
            }
        }
       .explore{
           display: flex;
           .bt{
            margin: auto;
            margin-top: 4em;
            h4{
                padding: 0;
            }
            .icon{
                background-color: transparent;
                border: 2px solid #814F8C;
                margin-left: 1em;
                img{
                    width: 65%;
                }
            }
            @media screen and(max-width:850px){
                margin-top: 2em;
            }
           }
           .bt:hover{
            .icon{
            border: 2px solid #fff !important;
            img{
                content: url('../../assets/explorew.png');
            }
            }
        }
       }
    }
}