
.why a {
    color: #000
}
.why{
    padding-left: 8em;
    padding-right: 8em;
    @media screen and (min-width:1450px){
        padding-right: 8em;
        padding-left: 8em;
        margin-top: 0em;
        
      }
      @media screen and (max-width:1110px) and (min-width:550px){
          padding-left: 4em;
          padding-right: 4em;
      }

      @media screen and (max-width:550px) and (min-width:0px){
        padding-left: 2em;
        padding-right: 2em;
        margin-top: 5em !important;
    }
    @media screen and (max-width:414px) {
        margin-top: 8em !important;
    }
    @media screen and (width:820px) {
        margin-top: -30em !important;
    }
    .head{
        text-align: center;
        color: #814F8C;
        font-size: 3em;
        text-transform: uppercase;
      
        @media screen and (max-width:1100px){
            font-size: 2em;
        }

    }
    .whyUtopia{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: #fff;
        margin-top: 3em;
        @media screen and (max-width:1110px){
            display: block;
        }
        @media screen and (min-width:1450px) {
            margin-top: 2em;
        }
        .area{
            width: 40vw;
            background-color: #814F8C;
            border-radius: 30px;
            padding: 3em 2em;
            padding-right: 2em;
            margin-top: 4em;
            @media screen and (max-width:1110px){
            margin: auto;
            width: fit-content;
            height: fit-content;
            margin-top: 5em;
            padding-top: 2em;
            padding-bottom: 2em;
            }
            @media screen and (max-width:1450px) and(min-width:1110px) {
                width: 37vw;
            }
        }
    
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width:1110px) {
                display: flex;
                flex-direction: column-reverse;
            }
            img{
                position: absolute;
                left: 35%;
                margin-bottom: 15vw;
                width: 12%;
                @media screen and (max-width:1110px) {
                  position: inherit;
                  margin-bottom: 0;
                  width: 50%;
                }
            }
            h1{
                text-transform: uppercase;
                @media screen and (min-width:1110px) and(max-width:1450px) {
                    font-size: 1.5em;
                }
                @media screen and (max-width:1110px) {
                padding-bottom: .5em;
                padding-top: .5em;
                }
                @media screen and (max-width:550px) and (min-width:0px){
                    font-size: 1.2em;
                }
            }
        }
        
        .area:nth-child(1){
            @media screen and (min-width:1110px) {
            // margin-right: 2em;
            img{
                left: 35%;
                margin-bottom: 8%;
                -webkit-margin-before: -4%;

            }
        }
        @media screen and (max-width:1110px) {
            // margin-right: 1em;
            img{
                padding-top: 5%;
                left: 35%;
                margin-bottom: 8%;
                -webkit-margin-before: -4%;

            }
        }
        }
        .area:nth-child(2){
            @media screen and (min-width:1110px) {
            img{
                left: 79%;
                width: 10%;
                margin-bottom: 12%;
                -webkit-margin-before: -6%;
                -webkit-margin-start: 3em;

            }
        }
        @media screen and (max-width:1110px) {
            margin-top: 10%;
            img{
                padding-top: 5%;
                width: 40%;
                margin-bottom: 10%;
                margin-right: 3em;
                -webkit-margin-before: -6%;
                -webkit-margin-start: 3em;

            }
        }
        }
        .area:nth-child(3){
            margin-left: auto;
            margin-right: auto;
            @media screen and (min-width:951px) {
                margin-top: 15%;
            img{
                left: 60%;
                width: 9%;
                margin-bottom: 12%;
                -webkit-margin-before: -6%;
                
            }
        }
        @media screen and (max-width:1110px) {
            margin-top: 10%;
        img{
            padding-top: 5%;
            left: 60%;
            width: 50%;
            margin-bottom: 10%;
            -webkit-margin-before: -6%;
            
        }
    }
        }
        h4{
            font-weight: 400;
            font-size: 1em;
            @media screen and (max-width:1110px) {
            text-align: center;
            }
            @media screen and (max-width:550px) and (min-width:0px){
                font-size: .8em;
            }
            @media screen and (min-width:1450px) {
                font-size: 24px;
                font-weight: 400;
                padding-top: 1em;
            }
        }
    }
}