.UNBCHero {
  background: rgb(106, 141, 115);
  background: linear-gradient(90deg, rgba(106, 141, 115, 1) 50%, rgba(224, 255, 112, 0.8) 100%);
  border-radius: 24px;
  margin-left: 5em;
  margin-right: 5em;
  @media screen and (min-width: 1450px) {
    margin-left: 8em;
    margin-right: 8em;
  }
  @media screen and (max-width: 1110px) and (min-width: 0px) {
    margin-left: 0em;
    margin-right: 0em;
  }

  .h-main {
    background-image: url("../../assets/dotedbg.png");
    padding-left: 6em;
    padding-right: 0em;
    padding-top: 9em;
    padding-bottom: 2em;
    display: flex;
    justify-items: center;
    @media screen and (min-width: 1450px) {
      padding-right: 6em;
    }
    @media screen and (max-width: 1110px) and (min-width: 550px) {
      padding-left: 4em;
      padding-right: 4em;
      flex-direction: column;
    }

    @media screen and (max-width: 550px) and (min-width: 0px) {
      padding-left: 2em;
      padding-right: 2em;
      padding-top: 4em;
      flex-direction: column;
    }
    .a1 {
      width: 50%;
      margin: auto;
      @media screen and (max-width: 1110px) and (min-width: 0px) {
        width: auto;
        margin: inherit;
      }
      h1,
      h2 {
        color: #e0ff70;
        font-size: 4.5em;
        text-transform: uppercase;
        @media screen and (max-width: 1200px) and(min-width:950px) {
          font-size: 3em;
        }
        @media screen and (max-width: 950px) {
          font-size: 3.5em;
        }
      }
      h2 {
        font-weight: 400;
        font-size: 3em;
        @media screen and (max-width: 1200px) {
          font-size: 2em;
        }
      }
      h4 {
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 1em;
      }

      .unbcbt {
        display: flex;
        align-items: center;
        border: 2px solid #e0ff70;
        border-radius: 15px;
        padding: 1em 1.2em;
        width: fit-content;
        cursor: pointer;
        margin-top: 3em;
        .icon {
          background-color: #e0ff70;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
        }
        h4 {
          color: #e0ff70;
          padding-left: 1em;
          font-weight: 600;
          text-transform: uppercase;
          margin-top: 0;
        }
      }
      .unbcbt:hover {
        background-color: #dbf96e;
        h4 {
          color: #6a8d73;
        }
        .icon {
          background-color: #6a8d73;
          img {
            content: url("../../assets/spinye.png");
            width: 90%;
          }
        }
      }
    }
    .a2 {
      width: 50%;
      @media screen and (max-width: 1110px) and (min-width: 0px) {
        width: auto;
      }
      .spinwheel {
        margin-left: 4em;
        @media screen and (max-width: 1110px) and (min-width: 0px) {
          margin: auto;
          text-align: center;
          margin-top: 3em;
        }
        img {
          width: 80%;
          @media screen and (max-width: 1110px) and (min-width: 0px) {
            width: 60%;
          }
        }
      }
    }
  }
}
