@import 'https://use.typekit.net/emk8ndw.css';
body{
  font-family:  xenera, sans-serif;
}
a{
  text-decoration: none;
  color: #814F8C;
}
.none{
  display: none !important;
}