.CookieConsent {
    max-width: 500px;
    border-radius: 5px;
    padding: 20px 30px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 9999990px);
    width: 95%;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    opacity: .7;
  }
  
  .overlayclass { 
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .btnCookie{
    cursor: pointer;
    align-items: center;
      border: 2px solid #814F8C;
      border-radius: 15px;
      width: fit-content;
      background-color: #fff;
      color:rgb(80, 79, 79);
      padding: 10px;
      margin-right: 10px;

  }

  .btnCookie:hover{
    background-color: #814F8C !important;
    color: #fff !important;
  }